"use strict";

/**
 * Import our code
 */
import inputValidation from './input-validation/input-validation';
import {validationRules} from './validation-rules/validation-rules';
import rememberMe from './remember-me/remember-me';
/*import keyboardInput from "./keyboard-input/keyboard-input";*/
import {device, initTagging} from "./index";

/**
 * Init rememberMe checkbox listener, input validation and tagging
 */
export const init = (tagEntite: string, userId?: string, userDateOfBirth?: string, userPostalAddress?: string, title?: string): void => {

  const postalCode: string = userPostalAddress?.match(/(?:[0-8][0-9]|9[0-8])[0-9]{3}/g)?.shift();
  const sexe: string = 'M' === title ? 'HOMME' : 'FEMME';

  rememberMe.init();

  inputValidation.init(validationRules);

  let tagDevice = device?.platform?.type;
  if(tagDevice === 'desktop') {
    tagDevice = 'Desktop';
  } else if (tagDevice === 'mobile') {
    tagDevice = 'Mobile';
  } else if (tagDevice === 'tablet') {
    tagDevice = 'Tablette';
  }

  let tags: any;

  tags = {
    contact: {
      origine: "transverse"
    },
    contenu: {
      device: tagDevice,
      entite: tagEntite,
      fil_ariane: ''
    }
  };

  if (userId || userDateOfBirth || postalCode) {
    tags.crm = {
      identifiant_crm: userId,
      date_naissance: userDateOfBirth,
      code_postal: postalCode,
      sexe: sexe
    }
  }

  initTagging(
      tags,
      {
        permanentKey:['contenu', 'CRM', 'contact'],
        logDebug:true
      }
  );
};
